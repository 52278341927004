import {loadStripe} from '@stripe/stripe-js';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {Text} from 'react-native';

import EtherButton from './EtherButton';
import EtherDialog from './EtherDialog.js';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {isProd} from '../utils/common/funcs';
import {createStripeSession} from '../utils/common/orders';
import etherFetch from '../utils/ether-fetch/etherFetch';

const PROD_KEY =
  'pk_live_51IUxZgGbRqCaF121oFdRh6jm5dar9uCyCs2VjLrEanRsudoK6iOmfZZpBzAISO1aLNEBCyGmCV1GLNBFWb8jwShz004dRsg55I';
const TEST_KEY =
  'pk_test_51IUxZgGbRqCaF121QGC5J8Z9LAXQctj8bArbsgnpgT5ajY2ywZt4WTeLP61e2C7YOJt8KjyHcIkKkwgNnyjgevbr00DzxSjDz0';

const stripePromise = loadStripe(isProd() ? PROD_KEY : TEST_KEY);

async function checkForAccessCode(eventId) {
  try {
    const res = await etherFetch('/packs/event/has-access-code', {
      method: 'POST',
      body: {
        eventId,
      },
    });
    const data = await res.json();
    return data.hasAccessCode;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export default function StripeCheckout({
  cart,
  style: overrides,
  textStyle,
  buttonText,
}) {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const {style} = useTheme(getThemedStyles);
  const orderName = cart.items.map((item) => item.pack.name).join(', ');

  function onAccessCodeSubmit() {
    setShowAccessCodeModal(false);
    requestStripe();
  }

  async function requestStripe() {
    setStatus(STATUS.BUSY);
    try {
      if (cart.eventId) {
        const buyRequiresCode = await checkForAccessCode(cart.eventId);
        if (buyRequiresCode && !accessCode) {
          setShowAccessCodeModal(true);
          setStatus(STATUS.IDLE);
          return;
        }
      }
    } catch (err) {
      console.error(err);
      return;
    }

    try {
      const stripe = await stripePromise;
      const session = await createStripeSession(
        orderName,
        cart._id,
        accessCode,
      ).catch(() => setAccessCode(''));
      if (session) {
        await stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
      setStatus(STATUS.INVALID);
    } catch (err) {
      toast.error(`Error creating checkout session:\n${err?.message}`);
    }

    setStatus(STATUS.SUCCESS);
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, []);

  const accessCodeControls = [
    {
      text: 'Cancel',
      onPress: () => {
        setAccessCode('');
        setShowAccessCodeModal(false);
      },
    },
    {
      text: 'Submit',
      onPress: () => requestStripe(),
      variant: 'primary',
    },
  ];

  return message ? (
    <section>
      <p>{message}</p>
    </section>
  ) : (
    <EtherButton style={overrides} onPress={requestStripe} status={status}>
      <EtherDialog
        header="Enter Access Code"
        subheader="The content you are trying to purchase is locked. Please enter the access code, or contact your store admin."
        controls={accessCodeControls}
        onClose={() => setShowAccessCodeModal(false)}
        show={showAccessCodeModal}
        status={status}
        style={style.dialog}
      >
        <EtherInput
          placeholder="Access Code"
          value={accessCode}
          onChangeText={setAccessCode}
          onSubmitEditing={onAccessCodeSubmit}
          style={style.textInput}
        />
      </EtherDialog>
      <Text style={textStyle}>{buttonText}</Text>
    </EtherButton>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  dialog: {
    maxWidth: 500,
  },
  textInput: {
    margin: 5,
  },
});
