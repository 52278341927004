import {faIcons, faStamp} from '@fortawesome/free-solid-svg-icons';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {View, Text, Switch} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import {StripeQuarter} from './StripeQuarter';
import EtherButton from '../../components/EtherButton';
import EtherInput from '../../components/EtherInput';
import FaIcon from '../../components/FaIcon';
import MoneyInput from '../../components/MoneyInput';
import UploadImageModal from '../../components/UploadImageModal.web';
import {UserInformation} from '../../components/UserInformation';
import {useOperator} from '../../context/OperatorContext';
import {useTheme} from '../../context/ThemeContext';
import {
  STATUS,
  DEFAULTPACKPRICE,
  DEFAULTASSETPRICE,
} from '../../utils/common/constants';
import {formatPhoneNumber} from '../../utils/common/funcs';
import {getStripeAccountLink} from '../../utils/common/orders';
import etherFetch from '../../utils/ether-fetch/etherFetch';

const GRID_SIZES = {
  margin: 20,
  one: 180,
  two: 400,
  three: 620,
  four: 840,
};

const pricingTypes = {
  pack: 'pack',
  asset: 'asset',
};

export default function Profile({navigation: {navigate}}) {
  const {style, values} = useTheme(getThemedStyles);
  const {operatorData, uploadFile, setOperatorData} = useOperator();

  const [status, setStatus] = useState(STATUS.IDLE);
  const [showWatermarkModal, setShowWatermarkModal] = useState(false);
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [stripeAccountUrl, setStripeAccountUrl] = useState(null);
  const [stripeId, setStripeId] = useState(false);
  const [packPrice, setPackPrice] = useState('0');
  const [assetPrice, setAssetPrice] = useState('0');
  const [displayData, setDisplayData] = useState(null);
  const [currentCustomUrl, setCurrentCustomUrl] = useState(null);
  const [customUrl, setCustomUrl] = useState(null);
  const [currentWelcomeText, setCurrentWelcomeText] = useState('');
  const [welcomeText, setWelcomeText] = useState('');
  const [pricingToggle, setPricingToggle] = useState(pricingTypes.pack);

  function onSubmitPrice() {
    if (status === STATUS.IDLE) {
      etherFetch('/config/set-users-prices', {
        method: 'PUT',
        body: {packPrice, assetPrice},
      })
        .then((res) => res.json())
        .then(() => {
          setStatus(STATUS.VALID);
          toast.success(`Prices updated 👍`);
        })
        .catch(() => setStatus(STATUS.INVALID))
        .finally(() => setTimeout(() => setStatus(STATUS.IDLE), 3000));
    }
  }

  function onTogglePricing(newValue) {
    setPricingToggle(newValue);
    const mode = newValue ? pricingTypes.asset : pricingTypes.pack;
    if (status === STATUS.IDLE) {
      setStatus(STATUS.BUSY);
      etherFetch('/config/set-pricing-mode', {
        method: 'PUT',
        body: {mode},
      })
        .then((res) => res.json())
        .then(() => {
          setPricingToggle(mode);
          setStatus(STATUS.VALID);
          toast.success(`Pricing mode updated 👍`);
        })
        .catch(() => setStatus(STATUS.INVALID))
        .finally(() => setTimeout(() => setStatus(STATUS.IDLE), 3000));
    }
  }

  async function onSaveCustomUrl() {
    if (currentCustomUrl === customUrl || status !== STATUS.IDLE) return;

    let sanitizedStoreSlug = null;

    try {
      const res = await etherFetch('/config/customurl', {
        method: 'PUT',
        body: {url: customUrl},
      });

      const {storeSlug} = await res.json();
      sanitizedStoreSlug = storeSlug;

      if (sanitizedStoreSlug === customUrl) {
        toast.success('Custom URL saved');
      } else {
        toast.success(
          'Custom URL saved, but had to be modified. No further action required',
        );
        toast.success(
          `Original: ${customUrl}\nModified: ${sanitizedStoreSlug}`,
        );
        toast.success(
          'Allowed characters: A-Z, 0-9. All other characters will be removed.',
        );
      }
    } catch (err) {
      if (err instanceof Response) {
        const {storeSlug} = await err.json();
        if (storeSlug) sanitizedStoreSlug = storeSlug;
      } else {
        console.error(err);
      }
    }

    if (!sanitizedStoreSlug) return;

    setCurrentCustomUrl(sanitizedStoreSlug);
    setCustomUrl(sanitizedStoreSlug);
    setOperatorData({
      ...operatorData,
      customization: {
        ...operatorData.customization,
        customUrl: sanitizedStoreSlug,
      },
    });
  }

  async function onSaveWelcomeText() {
    if (currentWelcomeText === welcomeText) return;
    const text = welcomeText;

    if (status === STATUS.IDLE) {
      const res = await etherFetch('/config/welcome-text', {
        method: 'PUT',
        body: {text: welcomeText},
      });
      if (res.status !== 200) {
        toast.error('Could not save welcome text');
        return;
      } else {
        toast.success('Custom URL saved');
      }
    }
    setCurrentWelcomeText(welcomeText);
    setWelcomeText(welcomeText);
    setOperatorData({
      ...operatorData,
      customization: {...operatorData.customization, welcomeText: text},
    });
  }

  useEffect(() => {
    if (!stripeId) return;
    getStripeAccountLink().then(({url}) => {
      setStripeAccountUrl(url);
    });
  }, [stripeId]);

  // Update local state when the OperatorContext reloads the operator data
  useEffect(() => {
    if (!operatorData) return;

    const {customization, identification, pricing} = operatorData;
    setPricingToggle(pricing.priceBy);
    setPackPrice(pricing.packPrice?.toString() || DEFAULTPACKPRICE);
    setAssetPrice(pricing.assetPrice?.toString() || DEFAULTASSETPRICE);
    setStripeId(operatorData.stripeId);
    setDisplayData({
      fname: identification.fname,
      lname: identification.lname,
      phone: identification.phone,
      email: identification.email,
      orgname: identification.orgname,
      username: operatorData.username,
    });
    setCustomUrl(customization.customUrl || identification.orgname);
    setCurrentCustomUrl(customization.customUrl || identification.orgname);
    setWelcomeText(
      customization.welcomeText ||
        `Welcome to ${identification.orgname}'s store!`,
    );
  }, [operatorData]);

  return (
    <SkeletonTheme
      baseColor={values.BGFIRST}
      highlightColor={values.SECOND}
      width={180}
    >
      <UploadImageModal
        title="Watermark preview"
        recommendedSize="1024x1024px"
        show={showWatermarkModal}
        onClose={() => setShowWatermarkModal(false)}
        onUpload={(files) =>
          uploadFile('/config/watermark', files[0]).then(() =>
            toast.success('Watermark upload successful'),
          )
        }
        preview={operatorData?.customization?.preview}
      />
      <UploadImageModal
        title="Logo upload"
        recommendedSize="1024x1024px"
        show={showLogoModal}
        onClose={() => setShowLogoModal(false)}
        onUpload={(files) =>
          uploadFile('/config/logo', files[0]).then(() =>
            toast.success('Logo upload successful'),
          )
        }
        preview={operatorData?.customization?.logo}
      />
      <View style={style.content}>
        <View style={style.contentRow}>
          <View style={style.card4Col}>
            <View style={style.profileName}>
              <Text style={style.profileText}>
                {displayData?.email && displayData?.lname ? (
                  displayData.fname + ' ' + displayData.lname
                ) : (
                  <Skeleton width={240} />
                )}
              </Text>
              <Text style={style.profileSubText}>
                {displayData?.email ? displayData.email : <Skeleton />}
              </Text>
              <Text style={style.profileSubText}>
                {displayData?.phone ? (
                  `+1 ${formatPhoneNumber(displayData.phone)}`
                ) : (
                  <Skeleton />
                )}
              </Text>
              <Text style={style.profileSubTextOrg}>
                {displayData?.username ? (
                  `Username: ${displayData.username}`
                ) : (
                  <Skeleton />
                )}
              </Text>
              <Text style={style.profileSubTextOrg}>
                {displayData?.orgname ? (
                  `Company: ${displayData.orgname}`
                ) : (
                  <Skeleton />
                )}
              </Text>
            </View>
          </View>
        </View>
        <View style={style.contentRow}>
          <View style={[style.card2Col, {height: 580}]}>
            {displayData?.fname ? (
              <UserInformation userInfo={displayData} />
            ) : (
              <ActivityIndicator size={64} color={values.FIRST} />
            )}
          </View>
          <View>
            <View style={style.card2Col}>
              {stripeAccountUrl || !stripeId ? (
                <StripeQuarter
                  stripeId={stripeId}
                  stripeAccountUrl={stripeAccountUrl}
                />
              ) : (
                <ActivityIndicator
                  style={style.quarter}
                  size={42}
                  color={values.FIRST}
                />
              )}
            </View>
            <View style={style.card2Col}>
              <View style={style.priceToggleContainer}>
                <Text style={style.switchText}>Price by Pack</Text>
                <Switch
                  trackColor={{false: values.FIRST, true: values.FIRST}}
                  thumbColor={values.BGFIRST}
                  activeThumbColor={values.BGFIRST}
                  onValueChange={onTogglePricing}
                  value={pricingToggle === pricingTypes.asset}
                  disabled={status === STATUS.BUSY}
                />
                <Text style={style.switchText}>Price by Asset</Text>
              </View>
              <Text style={style.descriptionText}>
                All items are bundled into packs. Please choose your preferred
                pricing method: per pack or per individual asset.
              </Text>
            </View>
            <View style={style.card2Col}>
              <View style={style.cardRow}>
                <MoneyInput
                  style={style.input}
                  inputStyle={{textAlign: 'right'}}
                  value={packPrice}
                  title="Pack Price"
                  onChange={(newPrice) => setPackPrice(newPrice)}
                  onSubmitEditing={onSubmitPrice}
                  money
                />
                <MoneyInput
                  style={style.input}
                  inputStyle={{textAlign: 'right'}}
                  value={assetPrice}
                  title="Asset Price"
                  onChange={(newPrice) => setAssetPrice(newPrice)}
                  onSubmitEditing={onSubmitPrice}
                  money
                />
              </View>
              <View style={style.cardRow}>
                <EtherButton
                  style={style.saveButton}
                  onPress={onSubmitPrice}
                  status={STATUS.IDLE}
                >
                  <Text style={style.buttonText}>Save</Text>
                </EtherButton>
              </View>
            </View>
          </View>
        </View>
        <View style={style.contentRow}>
          <View style={[style.card2Col, {marginTop: -25}]}>
            <EtherInput
              style={{flex: 1}}
              title="Custom URL"
              description={`Allowed characters: A-Z, 0-9. Enter your custom URL. It will appear like this: https://EtherMedia.app/kiosk/${currentCustomUrl}.`}
              placeholder="URL"
              value={customUrl}
              onChangeText={setCustomUrl}
              status={STATUS.IDLE}
              btnText="Save"
              onPress={onSaveCustomUrl}
            />
            <EtherInput
              style={{flex: 1, marginTop: 30}}
              title="Store Welcome Text"
              description={`This text will welcome your customers in the store's main page. We recommend 50 characters, you're using: ${welcomeText.length}`}
              placeholder="URL"
              value={welcomeText}
              onChangeText={setWelcomeText}
              status={STATUS.IDLE}
              btnText="Save"
              onPress={onSaveWelcomeText}
            />
          </View>
          <View style={style.optionsContainer}>
            <EtherButton
              style={style.card1Col}
              onPress={() => setShowWatermarkModal(true)}
            >
              <FaIcon icon={faStamp} color={values.SECOND} size={60} />
              <Text style={style.optionsText}>Watermark</Text>
            </EtherButton>
            <EtherButton
              style={style.card1Col}
              onPress={() => setShowLogoModal(true)}
            >
              <FaIcon icon={faIcons} color={values.SECOND} size={60} />
              <Text style={style.optionsText}>Logo</Text>
            </EtherButton>
          </View>
        </View>
      </View>
    </SkeletonTheme>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonText: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  card1Col: {
    flex: 1,
    minWidth: GRID_SIZES.one,
    maxWidth: GRID_SIZES.one,
    minHeight: 180,
    padding: 20,
    margin: GRID_SIZES.margin,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    overflow: 'hidden',
    mobile: {
      width: '100%',
      marginLeft: 0,
    },
  },
  card2Col: {
    flex: 1,
    minWidth: GRID_SIZES.two,
    maxWidth: GRID_SIZES.two,
    minHeight: 180,
    padding: 20,
    margin: GRID_SIZES.margin,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    overflow: 'hidden',
    mobile: {
      width: '100%',
      marginLeft: 0,
    },
  },
  card3Col: {
    flex: 1,
    minWidth: GRID_SIZES.three,
    maxWidth: GRID_SIZES.three,
    minHeight: 180,
    padding: 20,
    margin: GRID_SIZES.margin,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    overflow: 'hidden',
    mobile: {
      width: '100%',
      marginLeft: 0,
    },
  },
  card4Col: {
    flex: 1,
    minWidth: GRID_SIZES.four,
    maxWidth: GRID_SIZES.four,
    minHeight: 180,
    padding: 20,
    margin: GRID_SIZES.margin,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    overflow: 'hidden',
    mobile: {
      width: '100%',
      marginLeft: 0,
    },
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  content: {
    alignSelf: 'center',
    flexDirection: 'column',
    mobile: {
      paddingHorizontal: 40,
    },
  },
  contentRow: {
    flexDirection: 'row',
    mobile: {
      flexDirection: 'column',
    },
  },
  descriptionText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    textAlign: 'center',
    color: theme.DARK,
    maxWidth: 360,
    marginBottom: 20,
    mobile: {
      maxWidth: 220,
    },
  },
  input: {
    width: 100,
    height: 80,
  },
  optionsContainer: {
    flexDirection: 'row',
  },
  optionsText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    marginTop: 20,
    color: theme.DARK,
  },
  priceToggleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 30,
  },
  profileName: {
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  profileSubText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  profileSubTextOrg: {
    alignSelf: 'flex-end',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  profileText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.DARK,
  },
  quarter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
  },
  saveButton: {
    marginTop: 20,
    width: 150,
  },
  switchText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
});
